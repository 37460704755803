// import React from 'react';
// import './video-content-tile.css';
// import { Link } from 'gatsby';
// import Img from 'gatsby-image';
// import { StaticImage } from 'gatsby-plugin-image';

// type CaseStudyTileProps = {
//     to: string;
//     title: string;
//     headline: string;
//     image: string;
//     width?: string;
//     id: string;
//     popup: any;
// };

// const VideoContentTile: React.FC<CaseStudyTileProps> = ({ to, title, headline, image, width, id }) => {
//     console.log(image)
//     return (
//         <Link to={`/videos${to}`} className={'case-study-tile'} target='_blank' style={{ width: width }}>
//             <div className="image-wrapper" style={{ height: "100%", minWidth: "300px" }}>
//                 <img src={image} alt={`AptEdge | ${title}`} width={"100%"}/>
//             </div>

//             <div className="case-study-tile-text-wrapper">
//                 <h3 className={'text-md bold teal'}>{title}</h3>
//                 {/* <div className="case-study-tile-details-wrapper flex text-black" style={{ gap: "1em", opacity: "0.45" }}>
//                     <p className="text-sm  case-study-tile-author medium" dangerouslySetInnerHTML={{ __html: author }}>
//                     </p>
//                 </div> */}
//                 <p className={"text-black"} dangerouslySetInnerHTML={{ __html: headline }}></p>
//             </div>
//         </Link>
//     );
// };

// export default VideoContentTile;


import React, { useState } from 'react';
import './video-content-tile.css';
import { Link } from 'gatsby';

type CaseStudyTileProps = {
    to: string;
    title: string;
    headline: string;
    image: string;
    width?: string;
    id: string;
    popup?: string;
};

const VideoContentTile: React.FC<CaseStudyTileProps> = ({ to, title, headline, image, width, popup }) => {
    const [showVideo, setShowVideo] = useState(false);

    const handleVideoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (popup) {
            e.preventDefault();
            setShowVideo(true);
        }
    };

    const closeVideo = () => {
        setShowVideo(false);
    };

    return (
        <div className="case-study-tile" style={{ width: width }}>
            <div className="image-wrapper" style={{ height: "100%", minWidth: "100%" }}>
                <Link
                    to={popup ? '#' : `/videos${to}`}
                    onClick={handleVideoClick}
                    target={popup ? '_self' : '_blank'}
                >
                    <img src={image} alt={`AptEdge | ${title}`} width={"100%"} />
                </Link>

                {showVideo && (
                    <div className="video-overlay" onClick={closeVideo}>
                        <div className="video-player-container" onClick={(e) => e.stopPropagation()}>
                            <video width="1280" height="720" controls>
                                <source src={popup} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                )}
            </div>

            <div className="case-study-tile-text-wrapper">
                <h3 className={'text-md bold teal'}>{title}</h3>
                <p className={"text-black"} dangerouslySetInnerHTML={{ __html: headline }}></p>
            </div>
        </div>
    );
};

export default VideoContentTile;


