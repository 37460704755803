import React from 'react';
import './video-content-pageTiles.css';
import { ResourceData, VideolistingType } from '../../../types/caseStudyTypes';
import VideoContentTile from '../video-content-tile';

interface CaseStudyPageTilesProps {
    posts: {
        resourceData: VideolistingType[];
    };
}

const VideoContentPageTiles: React.FC<CaseStudyPageTilesProps> = ({ posts }) => {
    return (
        <section className="case-study-page-tiles-section">
            <div className="container">
                <div className="case-study-page-tiles-wrapper">
                    {posts?.resourceData?.map((item: VideolistingType, i: number) => (
                        <VideoContentTile
                            key={`case-study-${i}`}
                            to={item.link}
                            title={item.caseStudyTitle}
                            image={item.thumbnail}
                            headline={item.title} 
                            id={''}  
                            popup={item.popup}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default VideoContentPageTiles;
